<template>
    <div>
<!--        <div class="ad" id='div-gpt-ad-1728876197826-0' style='text-align:center;'></div>-->
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.ad{
    width: 300px;
    height: 250px;
    margin: 20px auto;
    background: #999;
}
</style>